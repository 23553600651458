import React from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import Navigator from '../components/navigator/navigator';

export default function Blog({ data }) {
  const blogTitle = 'Blog';

  const navigatorItems = [
    { text: 'home', url: '/' },
    { text: 'blog', url: '/blog' },
    {
      text: 'contact me',
      url: 'https://fb.me/ngoctram.nguyen.58118',
      external: true,
    },
  ];
  const blogEntries = data.allMarkdownRemark.nodes;

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>Ngọc Trâm Store - {blogTitle}</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Sản phẩm tiêu dùng và làm đẹp an toàn." />
        <link rel="canonical" href="https://www.ngoctramstore.com" />
      </Helmet>
      <header className="header">
        <div className="pure-g">
          <div className="pure-u-1">
            <Navigator value={navigatorItems} />
          </div>
        </div>
      </header>
      <div id="content" className="content">
        <div className="pure-g">
          <div className="pure-u-1">
            <h1 className="blog__title">{blogTitle}</h1>
          </div>
        </div>
        <div className="pure-g">
          {blogEntries.map((entry, index) => {
            return (
              <div key={entry.id} className="pure-u-1">
                <div className="blog-entry">
                  <h3 className="blog-entry__title">
                    <Link to={entry.frontmatter.slug}>{entry.frontmatter.title}</Link>
                  </h3>
                  <div className="blog-entry__spoiler">{entry.frontmatter.spoiler}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <footer id="footer">
        <div className="pure-g">
          <div className="pure-u-1">
            <hr />
            <ul className="info">
              <li className="info__item">Since 2020</li>
              <li className="info__item">
                <a
                  href="https://fb.me/ngoctram.nguyen.58118"
                  className="info__link"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  fb.me/ngoctram.nguyen.58118
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { slug: { regex: "^blog/" } } }) {
      nodes {
        frontmatter {
          title
          spoiler
          slug
          created(formatString: "mm dd YYYY")
        }
        id
      }
    }
  }
`;
